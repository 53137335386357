body
	width: 100vw
	height: 100vh
	background: radial-gradient(at 0 100%, #5f71a0, #86e8f1);

canvas
	position: fixed
	left: 0
	top: 0
	width: 100vw
	height: 100vh
	z-index: 100

.logo
	position fixed
	left: 10px;
	top: 10px;
	opacity: .5;
	width: 100px;
	transition: width .5s, left .5s, top .5s, margin-left .5s, margin-top .5s;
	z-index 1000

	&.jumbo
		left: 50%
		top: 50%
		width: 400px;
		margin-left: -200px
		margin-top: -160px;

footer
	position: fixed
	left: 20px
	bottom: 20px
	z-index 110