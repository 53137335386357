body {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(at 0 100%, #5f71a0, #86e8f1);
}

canvas {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

.logo {
  opacity: .5;
  width: 100px;
  z-index: 1000;
  transition: width .5s, left .5s, top .5s, margin-left .5s, margin-top .5s;
  position: fixed;
  top: 10px;
  left: 10px;
}

.logo.jumbo {
  width: 400px;
  margin-top: -160px;
  margin-left: -200px;
  top: 50%;
  left: 50%;
}

footer {
  z-index: 110;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

/*# sourceMappingURL=index.955caf74.css.map */
